<script>
import StaticPageLayout from '@/templates/static/StaticPageLayout'

export default {
  components: { StaticPageLayout },
  metaInfo() {
    return { title: 'About the article status monitor - Europe PMC' }
  },
  data() {
    return {
      title: 'About the Article Status Monitor',
      sections: [
        {
          id: 'how-the-article-status-monitor-works',
          heading: 'h2',
          title: 'How the article status monitor works',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
        },
        {
          id: 'how-to-access-updates-programmatically',
          heading: 'h2',
          title: 'How to access updates programmatically',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'how-status-updates-are-obtained',
          heading: 'h2',
          title: 'How status updates are obtained',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
      ],
    }
  },
}
</script>
<template>
  <static-page-layout
    id="about-article-status-monitor-page"
    :title="title"
    :sections="sections"
  >
    <template slot-scope="{ section }">
      <template v-if="section.id === 'how-the-article-status-monitor-works'">
        <p>
          The <a href="/ArticleStatusMonitor">Article Status Monitor</a> enables
          users to input a list of publication IDs including PMIDs, PMCIDs,
          PPRIDs and DOIs and returns status updates for publications. These
          status updates include:
        </p>
        <ul>
          <li>Preprint has been published in a journal</li>
          <li>Preprint has been withdrawn or removed</li>
          <li>Preprint has a more recent version</li>
          <li>Journal article has been retracted</li>
        </ul>
        <p>
          It is not always possible to match a preprint to a more recent or
          published version. For example, when an author changes the title of
          their preprint, or if a preprint server uses the same DOI for
          different preprint versions. For more information, see
          <a href="#">How status updates are obtained</a>
          below.
        </p>
      </template>
      <template
        v-else-if="section.id === 'how-to-access-updates-programmatically'"
      >
        <p>
          This data can also be accessed programmatically using the Status
          Update Search module of the Europe PMC RESTful API.
        </p>
      </template>
      <template v-else-if="section.id === 'how-status-updates-are-obtained'">
        <p>
          Some details are provided below about each status type including how
          the status data is obtained by Europe PMC and some limitations of the
          data.
        </p>
        <p class="h3">Preprint has been published in a journal</p>
        <p>
          Europe PMC indexes preprint abstracts and metadata from
          <a href="//www.crossref.org">Crossref</a> and the full text of
          COVID-19 preprints, or preprints supported Europe PMC funders. Find
          out which
          <a href="//europepmc.org/Preprints#preprint-servers"
            >preprint servers are indexed by Europe PMC</a
          >. Europe PMC uses links in Crossref (where available) as well as an
          algorithm to match preprint titles and author details to peer
          reviewed, journal published articles received from PubMed and PubMed
          Central. Sometimes authors change the title of their paper between
          posting an early version to a preprint server for feedback, and a
          peer-reviewed version being published in a journal. Therefore it is
          not always possible to match a preprint to its published version.
        </p>
        <p class="h3">Preprint has been withdrawn or removed</p>
        <p>
          Preprints are occasionally withdrawn by the authors or journal, or
          removed completely. For full text COVID-19 preprints, Europe PMC
          identifies and tags withdrawn or removed preprints with the article
          type <code>@article-type="preprint-withdrawal"</code> or
          <code>@article-type="preprint-removal"</code>. There is currently no
          metadata field in Crossref to identify withdrawn or removed preprints,
          so if a preprint only has an abstract in Europe PMC it is not
          currently possible to identify if it is withdrawn or removed.
        </p>
        <p class="h3">Preprint has a more recent version</p>
        <p>
          Europe PMC indexes different versions of preprints. If a more recent
          version of a preprint ID exists the Article Status Monitor will return
          this status update. However, it is not always possible to identify
          preprint versions. Some preprint servers (bioRxiv and medRxiv) use the
          same DOI for different preprint versions.
        </p>
        <p class="h3">Journal article has been retracted</p>
        <p>
          Europe PMC receives publisher retraction notices from Medline and
          these are linked to the retracted article.
        </p>
      </template>
    </template>
  </static-page-layout>
</template>
